// nameKey = translation key
export default [
    {
        path: '/',
        view: 'dashboard/Dashboard',
        icon: 'dashboard',
        nameKey: 'dashboard',
        requiredAccess: [],
        modes: [ 'site', 'home', 'theme', 'sharing', 'content provider' ]
    },
    {
        path: '/distributor',
        icon: 'account_tree',
        nameKey: 'distributor',
        requiredAccess: [{ hasContextType: 'Distributor' }],
        modes: ['home'],
        views: [
            {
                path: '/resels',
                view: 'distributor/Resellers',
                nameKey: 'manageResellers',
                requiredAccess: [],
                device: 'desktop',
                modes: ['home']
            },
            {
                path: '/distributor-themes',
                view: 'distributor/Themes',
                nameKey: 'distributorThemes',
                requiredAccess: [],
                device: 'desktop',
                modes: ['home']
            },
            {
                path: '/distributor-access',
                view: 'distributor/Access',
                nameKey: 'distributorUsersAndAccess',
                requiredAccess: [],
                device: 'desktop',
                modes: ['home']
            },
        ]
    },
    {
        path: '/reseller',
        icon: 'fact_check',
        nameKey: 'reseller',
        requiredAccess: [{ hasContextType: 'Reseller' }],
        modes: ['home'],
        views: [
            {
                path: '/licenses',
                view: 'reseller/Licenses',
                nameKey: window.location.hostname.includes('wave') ? 'customersAndLicenses' : 'sitesAndLicenses',
                requiredAccess: [],
                device: 'desktop',
                modes: ['home']
            },
            {
                path: '/reseller-themes',
                view: 'reseller/Themes',
                nameKey: 'resellerThemes',
                requiredAccess: [],
                device: 'desktop',
                modes: ['home'],
                requiredWhitelabel: [ 'default' ]
            },
            {
                path: '/reseller-access',
                view: 'reseller/Access',
                nameKey: 'resellerUsersAndAccess',
                requiredAccess: [],
                device: 'desktop',
                modes: ['home']
            },
            {
                path: '/legacies',
                view: 'reseller/Legacies',
                nameKey: 'externalSystems',
                requiredAccess: [],
                device: 'desktop',
                modes: ['home'],
                requiredWhitelabel: [ 'default' ]
            }
        ]
    },
    {
        path: '/hospitality',
        icon: 'local_hotel',
        nameKey: 'hospitality',
        requiredAccess: [],
        modes: [ 'site', 'theme', 'content provider' ],
        views: [
            {
                routeId: 'site-guests',
                path: '/guests',
                view: 'hospitality/Guests',
                nameKey: 'guests',
                requiredAccess: [ { feature: 'Hospitality', resource: 'Guests', operation: 'read' } ],
                modes: [ 'site', 'theme', 'content provider' ]
            },
            {
                routeId: 'site-wakeups',
                path: '/wakeups',
                view: 'hospitality/Wakeups',
                nameKey: 'wakeups',
                requiredAccess: [ { feature: 'WakeUp', resource: 'WakeUps', operation: 'read'} ],
                modes: [ 'site', 'theme', 'content provider' ]
            },
            {
                routeId: 'site-guest-messages',
                path: '/guest-messages',
                view: 'hospitality/GuestMessages',
                nameKey: 'guestMessages',
                requiredAccess: [ { feature: 'Hospitality', resource: 'GuestMessages', operation: 'read' } ],
                modes: [ 'site', 'theme', 'content provider' ]
            },
            {
                routeId: 'site-hospitality-rooms',
                path: '/rooms',
                view: 'hospitality/Rooms',
                nameKey: 'rooms',
                requiredAccess: [ { feature: 'Hospitality', resource: 'Locations', operation: 'read' } ],
                modes: [ 'site', 'theme', 'content provider' ]
            },
            {
                routeId: 'site-hospitality-vrc',
                path: '/virtual-remote-clients',
                icon: 'mdi_connection',
                view: 'hospitality/VirtualRemoteClients',
                nameKey: 'virtualRemoteClients',
                requiredAccess: [ { feature: 'Remote', resource: 'RemoteSessions', operation: 'read' } ],
                modes: [ 'site', 'theme', 'content provider' ]
            },
            {
                routeId: 'site-hospitality-config',
                path: '/config',
                view: 'hospitality/Config',
                nameKey: 'config',
                requiredAccess: [ { feature: 'Hospitality', resource: 'FeatureConfigurations', operation: 'update' } ],
                modes: [ 'site', 'theme', 'content provider' ]
            }
        ]
    },
    {
        path: '/screens',
        icon: 'tv',
        nameKey: 'tv',
        requiredAccess: [ ],
        modes: [ 'site', 'theme', 'content provider' ],
        views: [
            {
                path: '/theme-screen-config',
                view: 'themes/ThemeScreenConfig',
                nameKey: 'configuration',
                requiredAccess: [
                    { feature: 'ResourceManagement', resource: 'InteractiveMenuPages', operation: 'update' }
                ],
                modes: [ 'theme', 'content provider' ]
            },
            {
                path: '/theme-preview',
                view: 'themes/ThemePreview',
                nameKey: 'themePreview',
                requiredAccess: [
                    { feature: 'ResourceManagement', resource: 'InteractiveMenuPages', operation: 'update' }
                ],
                modes: [ 'theme', 'content provider' ]
            },
            {
                path: '/tv-screens-theme',
                view: 'tv/TvScreens',
                nameKey: 'tvScreensTheme',
                requiredAccess: [
                    { feature: 'ResourceManagement', resource: 'InteractiveMenuPages', operation: 'update' }
                ],
                modes: [ 'theme', 'content provider' ]
            },
            {
                routeId: 'site-tv-screens',
                path: '/tv-screens',
                view: 'tv/TvScreens',
                nameKey: 'tvScreens',
                requiredAccess: [
                    {feature: 'Clients', resource: 'Clients', limit: 'TvClientCount', minimum: 1 }
                ],
                modes: [ 'site' ]
            },
            {
                routeId: 'site-screen-configurations',
                path: '/screen-configurations',
                view: 'tv/ScreenConfigurations',
                nameKey: 'screenConfigurations',
                device: 'desktop',
                requiredAccess: [ { feature: 'Screens', resource: 'ScreenConfigurations', operation: 'update' } ],
                modes: [ 'site' ]
            },
            {
                routeId: 'site-schedules',
                path: '/schedules',
                view: 'tv/Schedules',
                nameKey: 'schedules',
                device: 'desktop',
                requiredAccess: [ { feature: 'Screens', resource: 'ConfigurationSchedules', operation: 'read' } ],
                modes: [ 'site' ]
            },
            {
                routeId: 'site-schedule-mapping',
                path: '/schedule-mapping',
                view: 'tv/ScheduleMapping',
                nameKey: 'schedulemapping',
                device: 'desktop',
                requiredAccess: [ { feature: 'Screens', resource: 'ScheduleRuleSets', operation: 'read' } ],
                modes: [ 'site' ]
            },
            {
                routeId: 'site-install-codes',
                path: '/install-codes',
                view: 'tv/InstallCodes',
                nameKey: 'installCodes',
                requiredAccess: [ { feature: 'Clients', resource: 'InstallCodes', operation: 'read' } ],
                modes: [ 'site', 'theme', 'content provider' ]
            }
        ]
    },
    {
        path: '/event',
        icon: 'mdi-calendar-clock',
        nameKey: 'event',
        requiredAccess: [ ],
        modes: [ 'site', 'theme', 'sharing', 'content provider'],
        views: [
            {
                routeId: 'event-events',
                path: '/events',
                view: 'event/Events',
                nameKey: 'events',
                requiredAccess: [ { feature: 'EventManagement', resource: 'Events', operation: 'read' } ],
                modes: [ 'site', 'theme', 'content provider' ]
            },
            {
                routeId: 'event-wayfinding',
                path: '/wayfinding',
                view: 'event/Wayfinding',
                nameKey: 'wayfinding',
                requiredAccess: [ { feature: 'EventManagement', resource: 'ScreenWayfindingConfigurations', operation: 'read' } ],
                modes: [ 'site', 'theme', 'content provider' ]
            },
            {
                routeId: 'event-integration-locations',
                path: '/integration-locations',
                view: 'event/IntegrationLocations',
                nameKey: 'integrationLocations',
                requiredAccess: [ { feature: 'EventManagement', resource: 'IntegrationLocations', operation: 'read' } ],
                modes: [ 'site', 'theme', 'content provider' ]
            },
            {
                routeId: 'event-config',
                path: '/config',
                view: 'event/EventConfig',
                nameKey: 'eventConfig',
                requiredAccess: [ { feature: 'EventManagement', resource: 'FeatureConfigurations', operation: 'update' } ],
                modes: [ 'site', 'theme', 'content provider' ]
            },
        ]
    },
    {
        path: '/signage',
        icon: 'mdi-television-guide',
        nameKey: 'signage',
        requiredAccess: [ ],
        modes: [ 'site', 'theme', 'content provider' ],
        views: [
            {
                routeId: 'site-signage-overview',
                path: '/signage-overview',
                view: 'signage/SignageSchedules',
                nameKey: 'signageSchedules',
                requiredAccess: [
                    { feature: 'Clients', resource: 'Clients', limit: 'SignageClientCount', minimum: 1 }
                ],
                modes: [ 'site', 'theme', 'content provider' ]
            },
            {
                routeId: 'site-signage-screens',
                path: '/signage-screens',
                view: 'signage/SignageScreens',
                nameKey: 'signageScreens',
                requiredAccess: [
                    { feature: 'Clients', resource: 'Clients', limit: 'SignageClientCount', minimum: 1 }
                ],
                modes: [ 'site', 'theme', 'content provider' ]
            },
            {
                routeId: 'site-signage-config',
                path: '/signage-config',
                view: 'signage/Config',
                nameKey: 'signageConfig',
                requiredAccess: [
                    { feature: 'Signage', resource: 'FeatureConfigurations', operation: 'update' }
                ],
                modes: [ 'site', 'theme', 'content provider' ]
            }
        ]
    },
    {
        path: '/content',
        icon: 'mdi-folder-multiple-image',
        nameKey: 'content',
        requiredAccess: [ ],
        modes: [ 'site', 'theme', 'sharing', 'content provider'],
        views: [
            {
                routeId: 'site-interactive-page',
                path: '/interactive-page',
                view: 'content/InteractivePageEditor',
                nameKey: 'interactivePages',
                device: 'desktop',
                requiredAccess: [ { feature: 'ResourceManagement', resource: 'InteractiveMenuPages', operation: 'read' } ],
                modes: [ 'site', 'theme', 'content provider' ]
            },
            {
                routeId: 'site-template-page',
                path: '/template-pages',
                view: 'content/TemplatePageEditor',
                nameKey: 'templatePages',
                device: 'desktop',
                requiredAccess: [
                    { feature: 'ResourceManagement', resource: 'CompositeMenuPages', operation: 'read' }
                ],
                modes: [ 'site', 'theme', 'content provider' ]
            },
            {
                routeId: 'site-templates',
                path: '/templates',
                view: 'content/Templates',
                nameKey: 'templates',
                device: 'desktop',
                requiredAccess: [ { feature: 'ResourceManagement', resource: 'Templates', operation: 'read' } ],
                modes: [ 'site', 'theme', 'content provider' ]
            },
            {
                routeId: 'site-documents',
                path: '/documents',
                view: 'content/Documents',
                nameKey: 'documents',
                requiredAccess: [ { feature: 'ResourceManagement', resource: 'Documents', operation: 'read' } ],
                modes: [ 'site', 'theme', 'content provider' ]
            },
            {
                routeId: 'site-playlists',
                path: '/playlists',
                view: 'content/Playlists',
                nameKey: 'playlists',
                requiredAccess: [ { feature: 'ResourceManagement', resource: 'Playlists', operation: 'read' } ],
                modes: [ 'site', 'theme', 'content provider' ]
            },
            {
                routeId: 'site-media',
                path: '/media',
                view: 'content/Resources',
                nameKey: 'media',
                requiredAccess: [ { feature: 'ResourceManagement', resource: 'Fonts', operation: 'read' } ],
                modes: [ 'site', 'theme', 'sharing', 'content provider' ]
            },
            {
                path: '/wayfindingIcons',
                view: 'content/Wayfinding',
                nameKey: 'wayfindingIcons',
                requiredAccess: [ { feature: 'ResourceManagement', resource: 'WayfindingIcons', operation: 'read' } ],
                modes: [ 'sharing', 'content provider' ]
            },
            {
                path: '/logos',
                view: 'content/Logos',
                nameKey: 'logos',
                requiredAccess: [ { feature: 'ResourceManagement', resource: 'Logos', operation: 'read' } ],
                modes: [ 'sharing', 'content provider' ]
            },
            {
                routeId: 'site-theme-importer',
                path: '/theme-importer',
                view: 'content/ThemeBrowser',
                nameKey: 'themeImport',
                device: 'desktop',
                requiredAccess: [ { feature: 'ContextManagement', resource: 'SharedContexts', operation: 'read' } ],
                modes: [ 'site', 'theme' ]
            },
            {
                routeId: 'content-configuration',
                path: '/content/content-configuration',
                icon: 'mdi_connection',
                view: 'content/ContentConfiguration',
                nameKey: 'contentConfiguration',
                requiredAccess: [
                    { feature: 'AgentManagement', resource: 'ResourceServerConfigurations', operation: 'update' },
                    { feature: 'ResourceManagement', resource: 'TablesConfigurations', operation: 'update' },
                ],
                modes: ['site', 'content provider'],
            },
        ]
    },
    {
        path: '/tvchannels',
        icon: 'mdi-radio-tower',
        nameKey: 'tvChannels',
        requiredAccess: [],
        modes: [ 'site', 'theme', 'content provider' ],
        views: [
            {
                routeId: 'site-channel-tables',
                path: '/channel-tables',
                view: 'tvchannels/ChannelTables',
                nameKey: 'channelTables',
                device: 'desktop',
                requiredAccess: [{feature: 'ChannelTableManagement', resource: 'ChannelTables', operation: 'read'}],
                modes: [ 'site', 'theme', 'content provider' ]
            },
            {
                routeId: 'site-media-channels',
                path: '/media-channels',
                view: 'tvchannels/MediaChannels',
                nameKey: 'mediaChannels',
                device: 'desktop',
                requiredAccess: [{feature: 'ChannelTableManagement', resource: 'MediaChannels', operation: 'read'}],
                modes: [ 'site', 'theme', 'content provider' ]
            }
        ]
    },
    {
        path: '/casts',
        icon: 'mdi-cast',
        nameKey: 'castManagement',
        requiredAccess: [],
        modes: [ 'site', 'theme', 'content provider'],
        views: [
            {
                routeId: 'site-chromecasts',
                path: '/chromecasts',
                view: 'cast/ChromeCasts',
                nameKey: 'chromecasts',
                requiredAccess: [ { feature: 'CastManagement', resource: 'RegisteredCasts', operation: 'read' } ],
                modes: [ 'site', 'theme', 'content provider']
            },
            {
                routeId: 'site-unregistered-casts',
                path: '/unregistered-casts',
                view: 'cast/UnregisteredCasts',
                nameKey: 'unregisteredCasts',
                requiredAccess: [ { feature: 'CastManagement', resource: 'UnregisteredCasts', operation: 'read' } ],
                modes: [ 'site', 'theme', 'content provider']
            },
            {
                routeId: 'site-active-casts',
                path: '/active-casts',
                view: 'cast/ActiveSessions',
                nameKey: 'activeCastSessions',
                device: 'desktop',
                requiredAccess: [ { feature: 'CastManagement', resource: 'ActiveCastSessions', operation: 'read' } ],
                modes: [ 'site', 'theme', 'content provider' ]
            },
            {
                routeId: 'site-cast-status',
                path: '/status',
                view: 'cast/Status',
                nameKey: 'castStatus',
                device: 'desktop',
                requiredAccess: [ { feature: 'CastManagement', resource: 'CastStatus', operation: 'read' } ],
                modes: [ 'site', 'theme', 'content provider' ]
            },
            {
                routeId: 'site-cast-statistics',
                path: '/statistics',
                view: 'cast/Statistics',
                nameKey: 'castStatistics',
                device: 'desktop',
                requiredAccess: [ { feature: 'CastManagement', resource: 'CastStatistics', operation: 'read' } ],
                modes: [ 'site', 'theme', 'content provider' ]
            },
            {
                routeId: 'site-cast-event-log',
                path: '/event-log',
                view: 'cast/EventLog',
                nameKey: 'castEventLogs',
                device: 'desktop',
                requiredAccess: [ { feature: 'CastManagement', resource: 'CastEventLogs', operation: 'read' } ],
                modes: [ 'site', 'theme', 'content provider' ]
            },
            {
                routeId: 'site-stream-allocator-config',
                path: '/stream-allocator',
                view: 'streamallocator/AllocatedStreams',
                nameKey: 'streamAllocator',
                device: 'desktop',
                requiredAccess: [
                    {feature: 'StreamAllocator', resource: 'AllocatedStreams', operation: 'read'}
                ],
                modes: [ 'site', 'theme', 'content provider' ]
            },
            {
                routeId: 'site-cast-configuration',
                path: '/configuration',
                view: 'cast/Configuration',
                nameKey: 'castConfiguration',
                device: 'desktop',
                requiredAccess: [ { feature: 'CastManagement', resource: 'CastProxyConfiguration', operation: 'update' }],
                modes: [ 'site', 'theme', 'content provider' ]
            },
        ]
    },
    {
        path: '/system',
        icon: 'admin_panel_settings',
        nameKey: 'system', // TODO: rename to something generic for System and Domain
        requiredAccess: [],
        modes: [ 'home' ],
        views: [
            {
                path: '/domains',
                view: 'system/Domains',
                nameKey: 'domains',
                requiredAccess: [{ rootContext: true, feature: 'ContextManagement', resource: 'Domains', operation: 'create'}],
                modes: ['home']
            },
            {
                path: '/distrs',
                view: 'system/Distributors',
                nameKey: 'distributors',
                requiredAccess: [{ rootContext: true, feature: 'ContextManagement', resource: 'Distributors', operation: 'read' }],
                modes: [ 'home' ]
            },
            {
                path: '/siteConfig',
                view: 'system/SitesConfig',
                nameKey: 'sites',
                requiredAccess: [{ rootContext: true, feature: 'ContextManagement', resource: 'SystemContextConfigurations', operation: 'read' }],
                modes: [ 'home' ]
            },
            {
                path: '/themes',
                view: 'system/Themes',
                nameKey: 'themes',
                requiredAccess: [{ rootContext: true, feature: 'ContextManagement', resource: 'Themes', operation: 'read' }],
                modes: [ 'home' ]
            },
            {   path: '/sharingContexts',
                view: 'system/SharingContexts',
                nameKey: 'sharingContexts',
                requiredAccess: [{ rootContext: true, feature: 'ContextManagement', resource: 'SharingContexts', operation: 'read' }],
                modes: [ 'home' ]},
            {
                path: '/contentProviderContexts',
                view: 'system/ContentProviderContexts',
                nameKey: 'contentProviderContexts',
                requiredAccess: [{ rootContext: true, feature: 'ContextManagement', resource: 'ContentProviderContexts', operation: 'read' }],
                modes: [ 'home' ]
            },
            {
                path: '/deleted-contexts',
                view: 'system/DeletedContexts',
                nameKey: 'deletedContexts',
                requiredAccess: [{ rootContext: true, feature: 'ContextManagement', resource: 'Contexts', operation: 'delete' }],
                modes: [ 'home' ]
            },
            {
                path: '/duplicate-registered-clients',
                view: 'system/DuplicateRegisteredClients',
                nameKey: 'duplicateRegisteredClients',
                requiredAccess: [{ rootContext: true, feature: 'Clients', resource: 'Clients', operation: 'read' }],
                modes: [ 'home' ]
            },
            {
                path: '/acnts',
                view: 'system/Accounts',
                icon: 'account_circle',
                nameKey: 'accounts',
                requiredAccess: [{ rootContext: true, feature: 'AccountManagement', resource: 'Accounts', operation: 'read' }],
                modes: [ 'home' ]
            },
            {
                path: '/apiacnts',
                view: 'system/ApiAccounts',
                icon: 'account_circle',
                nameKey: 'apiAccounts',
                requiredAccess: [{ rootContext: true, feature: 'AccountManagement', resource: 'ApiAccounts', operation: 'read' }],
                modes: ['home']
            },
            {
                path: '/owners',
                view: 'system/Owners',
                nameKey: 'owners',
                requiredAccess: [{ rootContext: true, feature: 'AccessManagement', resource: 'Owners', operation: 'read' }],
                modes: [ 'home' ]
            },
            {
                path: '/areas',
                view: 'system/Areas',
                nameKey: 'areas',
                requiredAccess: [{ rootContext: true, feature: 'AccessManagement', resource: 'Areas', operation: 'read' }],
                modes: [ 'home' ]
            },
            {
                path: '/products',
                view: 'system/Products',
                nameKey: 'products',
                requiredAccess: [{ rootContext: true, feature: 'LicenseManagement', resource: 'Products', operation: 'read' }],
                modes: [ 'home' ]
            },
            {
                path: '/role-management',
                view: 'system/RoleManagement',
                nameKey: 'roleManagement',
                requiredAccess: [{ rootContext: true, feature: 'AccessManagement', resource: 'Roles', operation: 'read' }],
                modes: [ 'home' ]
            },
            {
                path: '/privilegeSets',
                view: 'system/PrivilegeSets',
                nameKey: 'privilegeSets',
                requiredAccess: [{ rootContext: true, feature: 'AccessManagement', resource: 'PrivilegeSets', operation: 'create' }],
                modes: [ 'home' ]
            },
            {
                path: '/systemConfig',
                view: 'system/SystemConfig',
                nameKey: 'systemConfig',
                requiredAccess: [{ rootContext: true, feature: 'ContextManagement', resource: 'SystemConfig', operation: 'read' }],
                modes: [ 'home' ]
            },
            {
                path: '/tvSearch',
                view: 'system/TvSearch',
                nameKey: 'tvSearch',
                requiredAccess: [{ rootContext: true, feature: 'Clients', resource: 'Clients', operation: 'read' }],
                modes: [ 'home' ]
            },
            {
                path: '/airlineLogos',
                view: 'system/AirlineLogos',
                nameKey: 'airlineLogos',
                requiredAccess: [{ rootContext: true, feature: 'Flights', resource: 'FlightLogos', operation: 'read' }],
                modes: [ 'home' ]
            },
            {
                path: '/auditLogs',
                view: 'system/AuditLogs',
                nameKey: 'systemAuditLogs',
                requiredAccess: [{ rootContext: true, feature: 'Audit', resource: 'AuditLogEntries', operation: 'read' }],
                modes: [ 'home' ]
            },
            {
                path: '/resourceCopy',
                view: 'system/ResourceCopy',
                nameKey: 'resourceCopy',
                requiredAccess: [{ rootContext: true, feature: 'ContextManagement', resource: 'Contexts', operation: 'read' }],
                modes: [ 'home' ]
            }
        ]
    },
    {
        path: '/admin',
        icon: 'mdi-shield-account',
        nameKey: 'administration',
        requiredAccess: [],
        modes: ['site', 'theme', 'content provider'],
        views: [
            {
                routeId: 'site-locations',
                path: '/locations',
                icon: 'mdi-office-building',
                view: 'deployment/Locations',
                nameKey: 'locationSetup',
                requiredAccess: [{feature: 'ContextLocations', resource: 'Locations', operation: 'create'}],
                modes: ['site', 'theme', 'content provider'],
                requiredWhitelabel: [ 'default' ]
            },
            {
                routeId: 'site-accounts',
                additionalAccessToRoutesIds: ['site-system-default-roles'],
                path: '/deployment/accounts',
                icon: 'account_circle',
                view: 'deployment/Access',
                nameKey: 'usersAndAccess',
                requiredAccess: [ { feature: 'AccessManagement', resource: 'ContextAccess', operation: 'read'} ],
                modes: ['site', 'theme', 'content provider'],
                requiredWhitelabel: [ 'default' ],
            },
            {
                routeId: 'site-roles',
                path: '/deployment/roles',
                icon: 'account_circle',
                view: 'deployment/Roles',
                nameKey: 'roles',
                requiredAccess: [ { feature: 'AccessManagement', resource: 'PrivilegeSets', operation: 'read'} ],
                modes: ['site', 'theme', 'content provider'],
                requiredWhitelabel: [ 'default' ]
            },
            {
                routeId: 'site-agents',
                path: '/deployment/agents',
                icon: 'mdi_connection',
                view: 'deployment/Agents',
                nameKey: 'agents',
                requiredAccess: [ { feature: 'Clients', resource: 'Clients', limit: 'AgentClientCount', minimum: 1 }],
                modes: ['site'],
                requiredWhitelabel: [ 'default' ]
            },
            {
                routeId: 'site-pms',
                path: '/deployment/pms',
                icon: 'mdi_connection',
                view: 'deployment/Pms',
                nameKey: 'pmsConfiguration',
                requiredAccess: [ { feature: 'Pms', resource: 'FeatureConfigurations', operation: 'update' } ],
                modes: ['site', 'theme', 'content provider']
            },
            {
                routeId: 'site-chromecast-config',
                path: 'deployment/chromecastConfig',
                view: 'deployment/ChromecastConfig',
                nameKey: 'chromecastConfig',
                device: 'desktop',
                requiredAccess: [ { feature: 'CastManagement', resource: 'NetworkProxyConfig', operation: 'update' } ],
                modes: [ 'site', 'theme', 'content provider' ]
            },
            {
                routeId: 'pms-log-entries',
                path: '/deployment/pmsLogEntries',
                view: 'deployment/PmsLogEntries',
                nameKey: 'pmsLogEntries',
                requiredAccess: [{feature: 'Pms', resource: 'LogEntries', operation: 'read'}],
                modes: [ 'site' ]
            },
            {
                routeId: 'site-audit-logs',
                path: '/deployment/auditLogs',
                view: 'deployment/AuditLogs',
                nameKey: 'deploymentAuditLogs',
                requiredAccess: [{ feature: 'Audit', resource: 'AuditLogEntries', operation: 'read' }],
                modes: [ 'site', 'content provider' ]
            },
            {
                routeId: 'variables',
                path: '/deployment/variables',
                view: 'deployment/Variables',
                nameKey: 'deploymentVariables',
                requiredAccess: [{ feature: 'ContextManagement', resource: 'ContentProviderContexts', operation: 'read' }],
                modes: [ 'site', 'content provider' ]
            },

        ]
    }
];
